import DashboardLayout from "@/components/layouts/DashboardLayout"
import Image from "next/image"

export default function Custom404Page() {
  return (
    <DashboardLayout title="Not Found">
      <div className="flex flex-column m-16">
        <Image
          src="/img/no-results.svg"
          width="300"
          height="300"
          alt={"Not Found"}
        />
        <h2 className="text-3xl font-semibold text-center mb-4">
          404 | Not Found
        </h2>
        <p className="text-lg text-center">
          Whoops, the page you're looking for doesn't exist.
        </p>
      </div>
    </DashboardLayout>
  )
}
